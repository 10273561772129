import {AuthGuard, redirectUnauthorizedTo} from '@angular/fire/auth-guard';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from './enviroments/enviroment';
import { getAuth } from 'firebase/auth';
import { provideAuth } from '@angular/fire/auth';
import { Routes } from '@angular/router';
import { CustomAuthGuard } from './auth/guard/custom-auth.guard';
import { CompanyAdminGuard } from './auth/guard/company-admin.guard';
import { superAdminGuard } from './auth/guard/super-admin.guard';
import { medicalStaffGuard } from './auth/guard/medical-staff.guard';
import { administrativeStaffGuard } from './auth/guard/administrative-staff.guard';
import { viewConsentGuard } from './auth/guard/view-consent.guard';

// const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'landing',
        pathMatch: 'full'
    },
    {
        path: 'landing',
        loadComponent: () => import('./pages/landing/landing.component').then(m => m.LandingComponent),
    },
    {
        path: 'dashboard',
        loadComponent: () => import('./dashboard/dashboard.component').then(m => m.DashboardComponent),
        // canActivate: [CustomAuthGuard]
        data: { breadcrumb: 'Inicio' }
    },
    {
        path: 'admin-console',
        loadComponent: () => import('./admin/admin-console/admin-console.component').then(m => m.AdminConsoleComponent),
        canActivate: [CustomAuthGuard],
        data: { breadcrumb: 'Consola administración' },
        children: [
            {
                path: 'create-company',
                loadComponent: () => import('./admin/create-company/create-company.component').then(m => m.CreateCompanyComponent),
                canActivate: [CustomAuthGuard, superAdminGuard],
                data: { breadcrumb: 'Crear empresa' }
            },
            {
                path: 'active-company',
                loadComponent: () => import('./admin/active-company/active-company.component').then(m => m.ActiveCompanyComponent),
                canActivate: [CustomAuthGuard, superAdminGuard],
                data: { breadcrumb: 'Activar empresa' }
            },
            {
                path: 'create-users',
                loadComponent: () => import('./admin/create-users/create-users.component').then(m => m.CreateUsersComponent),
                canActivate: [CustomAuthGuard, CompanyAdminGuard],
                data: { breadcrumb: 'Crear usuarios' }
            },
            {
                path: 'create-consent',
                loadComponent: () => import('./admin/create-consent/create-consent.component').then(m => m.CreateConsentComponent),
                canActivate: [CustomAuthGuard, administrativeStaffGuard],
                data: { breadcrumb: 'Crear consentimiento' }
            },
            {
                path: 'completed-consents',
                loadComponent: () => import('./admin/completed-consents/completed-consents.component').then(m => m.CompletedConsentsComponent),
                canActivate: [CustomAuthGuard],
                data: { breadcrumb: 'Consentimientos diligenciados' }
            },
            {
                path: 'view-consent/:id',
                loadComponent: () => import('./admin/view-consent/view-consent.component').then(m => m.ViewConsentComponent),
                canActivate: [CustomAuthGuard, viewConsentGuard], data: { breadcrumb: 'Ver consentimiento'} 
            },
            {
                path: 'advance-consent',
                loadComponent: () => import('./admin/advance-consent/advance-consent.component').then(m => m.AdvanceConsentComponent),
                canActivate: [CustomAuthGuard, administrativeStaffGuard],
                data: { breadcrumb: 'Consentimientos anticipados' }
            }
        ]
    },
    {
        path: 'patient',
        loadComponent: () => import('./patient/patient-identification/patient-identification.component').then(m => m.PatientIdentificationComponent),
        
        children: [
            {
                path: 'standard-consent/:id',
                loadComponent: () => import('./patient/consents/standard-consent/standard-consent.component').then(m => m.StandardConsentComponent),
                data: { breadcrum: 'Consentimiento informado'}
            }
        ]

    },
    {
        path: '403',
        loadComponent: () => import('./shared/components/unauthorized-access/unauthorized-access.component').then(m => m.UnauthorizedAccessComponent),
        data: { breadcrumb: 'Acceso no authorizado' }
    },
    {
        path: 'login',
        loadComponent: () => import('./auth/login/login.component').then(m => m.LoginComponent),
        providers: [
            provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
            provideAuth(() => getAuth())
          ]
    }
];
