// This project is developed by [Derwing Medina](https://derwing-portfolio.netlify.app/) for IPS Nueva Popayan 
export class Company {
    id: string;
    name: string;
    nit: string;
    address: string;
    phone: string;
    web: string;
    logoUrl: string;
    mainColor: string;
    secondaryColor: string;
  
    constructor(data: {
      id: string,
      name: string,
      nit: string,
      address: string,
      phone: string,
      web: string,
      logoUrl: string,
      mainColor: string,
      secondaryColor: string
    }) {
      // Asignamos los valores del objeto pasado al constructor
      this.id = data.id;
      this.name = data.name;
      this.nit = data.nit;
      this.address = data.address;
      this.phone = data.phone;
      this.web = data.web;
      this.logoUrl = data.logoUrl;
      this.mainColor = data.mainColor;
      this.secondaryColor = data.secondaryColor;
    }
  
    // Método para actualizar la información de la compañía
    updateCompany(data: Partial<Company>) {
      // Actualiza solo los campos que se pasen (parcialmente)
      Object.assign(this, data);
    }
  
    // Método para obtener la información formateada
    getCompanyInfo(): string {
      return `${this.name} (${this.nit}), ubicada en ${this.address}. Teléfono: ${this.phone}, Sitio web: ${this.web}`;
    }
  
    // Métodos adicionales según tus necesidades
    updateLogo(newLogoUrl: string) {
      this.logoUrl = newLogoUrl;
    }
  
    updateColors(mainColor: string, secondaryColor: string) {
      this.mainColor = mainColor;
      this.secondaryColor = secondaryColor;
    }
  }
  